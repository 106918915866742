import PropTypes from "prop-types"
import React from "react"
import { Navbar, Nav } from "react-bootstrap"
//import '../../styles/header.css';

const getMenuItems = menuItems => {
  return menuItems.map((menuItem, index) => {
    return (
      <Nav.Item key={index}>
        <Nav.Link
          href={menuItem.href}
          style={{ color: "#29829d", fontWeight: "600" }}
        >
          {menuItem.title}
        </Nav.Link>
      </Nav.Item>
    )
  })
}
const Menu = ({ clientName, menuItems }) => (
  <div className="header-container">
    <Navbar
      style={{ padding: " 0% 10%", height: "100px" }}
      bg="white"
      expand="lg"
      fixed="top"
    >
      <Navbar.Brand href="/">
        <img src={`/logo.png`} style={{ marginBottom: "0" }} alt="logo" />
      </Navbar.Brand>
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        className="navbar navbar-white bg-white"
      />
      <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
        <Nav
          class="navbar navbar-white bg-white"
          style={{
            borderBottomRightRadius: "1em",
            borderBottomLeftRadius: "1em",
          }}
          activeKey="/home"
          onSelect={selectedKey =>
            console.log(`clicked on route => ${selectedKey}`)
          }
        >
          {getMenuItems(menuItems)}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  </div>
)

Menu.propTypes = {
  siteTitle: PropTypes.string,
}

Menu.defaultProps = {
  siteTitle: ``,
}

export default Menu
