import PropTypes from "prop-types"
import React from "react"
import { Row, Col } from "react-bootstrap"
import "../../styles/footer.css"

const Footer = ({ siteTitle, siteUrl, footer }) => (
  <footer
    className=""
    style={{
      backgroundColor: footer.firstSection.backGroundColor,
      padding: "2% 10%",
    }}
  >
    <Row className="level-1">
      <Col sm={8}>
        <p
          className="client-description"
          dangerouslySetInnerHTML={{
            __html: footer.firstSection.leftSide[0].textContent,
          }}
        ></p>
      </Col>
      <Col sm={4}>
        <span
          className="address"
          dangerouslySetInnerHTML={{
            __html: footer.firstSection.rightSide[0].textContent,
          }}
        ></span>
      </Col>
    </Row>
    <Row className="level-0">
      <Col sm={9} className="copy-right">
        © {new Date().getFullYear()},{` `}
        <a href={siteUrl}>{siteTitle}</a>
        {` `} - {` `}Tous droits réservés.
        <a href={footer.secondSection.leftSide[0].url} className="term-of-use">
          {" "}
          {footer.secondSection.leftSide[0].textContent}{" "}
        </a>
      </Col>
    </Row>
  </footer>
)

Footer.propTypes = {
  siteTitle: PropTypes.string,
  siteUrl: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: ``,
  siteUrl: `#`,
}

export default Footer
