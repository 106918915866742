import React, { useEffect } from "react"
import PropTypes from "prop-types"
import PageFooter from "./PageFooter"
import PageMenu from "./PageMenu"
import "../../styles/app.css"
import "../../styles/layout.css"
import "bootstrap/dist/css/bootstrap.min.css"
import clientData from "../../client/data/data.json"

const BaseLayout = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false)
    }, 0)
    return () => clearTimeout(timer)
  }, [])
  return (
    <div style={{ display: `${isLoading ? "none" : "block"}` }}>
      <PageMenu
        clientName={clientData.clientName}
        menuItems={clientData.menu.menuItems}
      />
      <div className="layout-container">{children}</div>
      <PageFooter
        siteTitle={clientData.siteTitle}
        siteUrl={clientData.siteUrl}
        footer={clientData.footer}
      />
    </div>
  )
}

BaseLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default BaseLayout
